<template>
    <div class="body" v-loading="loading">
        <div class="top" v-show="contentshow">
           <div class="quire">
               <div>
                   <div class="quire_title">首次查询时间</div>
                   <div class="quire_p">{{data.scanDate}}</div>
               </div>
               <div>
                   <div class="quire_title">首次查询地址</div>
                   <div class="quire_p">{{data.scanAddr}}</div>
               </div>
               <div @click="nextScan(data.batchId)">
                   <div class="quire_title">第n次扫码</div>
                   <div class="quire_p">查看详情</div>
               </div>
           </div>
           <div class="list">
               <div class="list_title">{{data.brandName}}</div>
               <div class="li">
                   <div class="ui"><span class="dot"></span><span class="ui_title">品名</span></div>
                   <div class="ui_p">{{data.proName}}</div>
               </div>
               <div class="li" v-if="data.spec!='' " style="padding: 15px 0;">
                   <div class="ui"><span class="dot"></span><span class="ui_title">规格</span></div>
                   <div class="ui_p">{{data.spec}}</div>
               </div>
           </div>
           <div class="list" v-for="(item,index) in data.traceList" :key="index+ '1'">
               <div class="list_title">{{item.traceName}}</div>
               <div class="li buttom_li" v-for="(list,index) in item.nodeList" :key="index + '1aa'">
                   <div class="ui" ><span class="dot"></span><span class="ui_title" style="padding: 5px 0;">{{list.dtlTitle}}</span></div>
                   <div class="ui_img" v-if="list.dtlType == '1'"><el-image :src="changeUrl(list.dtlContent)" alt="" style="width:100;height:100%" :preview-src-list="list.urlList"></el-image></div>
                   <div class="ui_img" v-else-if="list.dtlType == '3'" @click="imgClick(list.urlList[0])"><el-image :src="changeUrl(list.dtlContent)" style="width:100;height:100%" >
                            <div slot="error" class="image-slot">
                                <p>点击预览PDF</p>
                            </div>
                            </el-image></div>
                   <div class="ui_img" v-else-if="list.dtlType == '2'"><Video :videoUrl='changeUrl(list.dtlContent)'/></div>
                   <div class="ui_p" v-else style="padding: 5px 0;">{{changeUrl(list.dtlContent)}}</div>
               </div>
               <div class="button" @click="certificate(item)">
                   <!-- <img src="../assets/icon1.png" alt="" style="width:18px"> -->
                   <span class="butSize">区块链标识：{{item.blockId}}</span>
                   <img src="../assets/next.png" alt="" style="width:5px">
               </div>
           </div>
           <van-button v-if="data.codeSum && data.codeSum!=0" style="width: 100%;background-color:darkorange;color:#fff" round @click="handleCheckJump">防伪查询</van-button>
        </div>
    </div>
</template>
<script>
import { Notify } from 'vant'
import Video from '@/components/video.vue'
export default {
    components:{
        Video
    },
    mounted(){
        this.batchId = this.$route.query.batchId
        // this.map()
        this.fixedPos()
        const body = document.getElementsByTagName('body')[0]
        window.addEventListener('popstate',()=>{
            body.style = ""
        })
    },
    data(){
        return{
            batchId: '',
            lng: String,
            lat: String,
            data: Object,
            loading: false,
            contentshow: true
        }
    },
    methods:{
        map(){
            if(localStorage.getItem("lng")){
                this.lng = localStorage.getItem("lng")
                this.lat = localStorage.getItem("lat")
                this.fixedPos()
            }else{
                var geolocation = new qq.maps.Geolocation("GFNBZ-GX6L2-GH4UU-C4OHK-YBIUT-FSFXJ", "trace");
                console.log(geolocation,'qq.map.geolocation，实例化定位');
                geolocation.getIpLocation( res =>{
                    console.log(res,'获取定位成功时');
                    this.lng = res.lng
                    this.lat = res.lat
                    localStorage.setItem("lng", res.lng)
                    localStorage.setItem("lat", res.lat)
                    this.fixedPos()
                },err=>{
                    this.fixedPos()
                });
            }
        },
        // PDF
        imgClick(e){
            location.href = e
        }, 
        fixedPos() {
            // 获取定位
            this.$axios.get('/show/index?batchId='+ this.batchId?this.batchId:localStorage.getItem('batchId') +'&lng=120.13026' +'&lat=30.25961').then(res=>{
                if(res.data.retCode == '9999'){
                    this.contentshow = false
                    Notify({ type: 'warning', message: res.data.retMsg });
                }else{
                    this.data = res.data.retData
                }
                this.loading = false
            })
        },
        nextScan(e){
            this.$router.push({name: 'scan',params:{id:e}})
        },
        certificate(e){
            this.$router.push({name: 'certificate',params:{data:e}})
        },
        changeUrl(url){
            return url
        },
        handleCheckJump(){
            let batchId = localStorage.getItem('batchId')
            let isIndex = localStorage.getItem('indexNo')
            this.$router.push({
                name:'check',
                query:{
                batchId,
                noIndex:(isIndex && isIndex!='')?false:true
                }
            })
        }
    }
}
</script>
<style scoped>
.body{
    
background: #F8F8F8;
}
.top{
    height: calc(100vh - 16px);
    background-image: url('../assets/bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 38vh;
}
.list_title{
    background:#f0f7fe;
    border-radius: 12px 12px 0px 0px;
    height: 44px;
    line-height: 44px;
    margin-top: 14px;
    padding: 0 15px;
    margin-bottom: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.quire{
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    font-size: 10px;
}
.quire_title{   
    color: rgba(255, 255, 255, 0.6);
}
.quire_p{
    color: #fff;
}
.button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(7, 117, 241, 0.03);
    border: 1px solid #0775F1;
    border-radius: 34px;
    height: 34px;
    padding: 5px 15px;
    margin-top: 30px;
}
.list{
    margin: 0 15px;
    background: #fff;
    border-radius: 12px 12px 0px 0px;
    padding-bottom: 28px;
}
.dot{
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #0775F1;
    border-radius: 50%;
    position: relative;
    left: -4px;
}
.li{
    display: flex;
    justify-content: space-between;
    margin: 0 15px;
    border-left: 1px dashed #0775F1;
}
.ui{
    display: flex;
    align-items: center;
}
.ui_title{
    color: #333333;
    margin-left: 5px;
}
.ui_p{
    color: #999999;
    width: 50%;
}
.butSize{
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0775F1;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.ui_img{
    width: 75px;
    height: 43px;
}
.buttom_li{
    align-items: end;
}
</style>